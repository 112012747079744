<template>
  <div class="d-none" id="lang-switcher" v-if="($i18n.locale = this.locale) == 'ab'">
  </div>
  <main class="bg-light-grey scroll-to-center english position-mobile-relative " id="main">
    <Hint />
    <Menu @en="changeLocale('en')" @ar="changeLocale('ar')" />
    <mobileHeader v-if="mobile" />
    <start lang="ar" class="en-mobie-none" />
    <start lang="en" class="ar-mobie-none" />
    <services />
    <OurApproach />
    <WorkedWith />
    <AboutUs />
    <LetsConnect />
  </main>
</template>
<script>

import start from "../components/sections/start.vue";
import services from "../components/sections/services.vue";
import Menu from "@/components/shared/menu.vue";
import OurApproach from "@/components/sections/ourApproach.vue";
import LetsConnect from "@/components/sections/letsConnect.vue";
import AboutUs from "@/components/sections/aboutUs.vue";
import WorkedWith from "@/components/sections/workedWith.vue";
import mobileHeader from "@/components/shared/mobileHeader.vue";
import LocaleHelper from "@/helpers/LocaleHelper";
import { useRouter } from "vue-router";
import Hint from '@/components/shared/hint.vue'

export default {
  data() {
    return {
      fullwidth: 0,
      screen: window.innerWidth,
      router: useRouter(),
      lang: LocaleHelper.getCurrentLocale(),
      locoScroll: null
    };
  },
  components: {
    start,
    services,
    Menu,
    OurApproach,
    LetsConnect,
    AboutUs,
    WorkedWith,
    mobileHeader,
    Hint
  },
  created() {
  },
  mounted() {
    this.fullwidth = this.calculateFullWidth();
    var here = this;
    this.changeLocale('en');

    const slider = document.querySelector('#main');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.style.cursor = 'initial';
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      slider.style.cursor = 'grabbing';
      slider.style.userSelect = 'none';
      const x = e.pageX - slider.offsetLeft;
      const walk = ( startX -x) 
      here.smoothScroll(slider , walk*0.08 , 200)
    });
    if (window.innerWidth > 1199) {
      this.changeLocaleOnScroll();
      $("#main").scrollTo('#en-home', 10);
      this.setupScroll();
    }

    $("main").scroll(function () {
      if (window.innerWidth <= 1199) {
          $(".id-card").each(function () {
            if (
              $(this).position().top <= $(document).scrollTop() + 300 &&
              $(this).position().top + $(this).outerHeight() > $(document).scrollTop() + 300
            ) {
              $(this).addClass(" animate__animated animate__slideInLeft");
              $(this).removeClass("hidden");
            }
          });
        }
         else {
          $(".three-logo-container").each(function () {
            if (
              $(this).position().left <= $(document).scrollLeft() + window.innerWidth / 2 &&
              $(this).position().left + $(this).outerWidth() >
              $(document).scrollLeft() + window.innerWidth / 2
            ) {
              // $(this).removeClass('animate__animated animate__fadeIn')
              $(this).addClass(" animate__animated animate__fadeIn");
              $(this).removeClass("hidden");
              $(this).next(".vertical").removeClass("hidden");
            }
          });

        }
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    document.querySelector("main").removeEventListener("scroll", this.onResize);
    document.querySelector("main").removeEventListener("wheel", this.onResize);
  },

  methods: {
    smoothScroll(domElement, pixel, delay) {
      // console.log('pixels : '+pixel+" delay : "+delay+" scrollLeft "+ domElement.scrollLeft)

      var here = this;
      const intervalToRepeat = 25;
      const step = (intervalToRepeat * pixel) / delay;
      if (Math.abs(domElement.scrollLeft + step) > this.fullwidth) {
        return;
      }

      if (Math.abs(step) < Math.abs(pixel) && Math.abs(step) > 0.5) {
        domElement.scrollLeft += step;
        setTimeout(function () {
          here.smoothScroll(domElement, pixel - step, delay)
        }, intervalToRepeat);
      }
    },
    calculateFullWidth() {
      var sections = document.querySelectorAll('.section');
      var fullwidth = 0;
      sections.forEach((section) => {
        fullwidth = section.clientWidth + fullwidth;
        // console.log(`width of ${section.id} : ${section.clientWidth}`)
        section.width = section.clientWidth;
      });
      return fullwidth
    },
    onResize() {
      if (window.innerWidth > 1199)
        this.setupScroll();
      this.screen = window.innerWidth;
    },
    changeLocale(localeCode) {
      LocaleHelper.changeLocale(localeCode);
      this.lang = localeCode;
      if (localeCode == 'en') {
        document.getElementById('main').style.direction = "ltr";
        document.getElementById('main').classList.remove('arabic');
        document.getElementById('main').classList.add('english');
        document.getElementById('main').classList.remove('to-left');
        document.getElementById('main').classList.add('to-right');
      }
      else {
        document.getElementById('main').style.direction = "rtl";
        document.getElementById('main').classList.remove('english');
        document.getElementById('main').classList.add('arabic');
        document.getElementById('main').classList.remove('to-left');
        document.getElementById('main').classList.add('to-right');
      }
    },
    changeLocaleOnScroll() {
      if (window.innerWidth > 1199) {
        var here = this;
        document.getElementById('main').style.direction = "ltr";
        var oldScrollX = document.getElementById('main').scrollLeft;
        document.getElementById('main').onscroll = function (e) {
          if (oldScrollX <= document.getElementById('main').scrollLeft
            && document.querySelector("#en-home").getBoundingClientRect().left <= 0
            && document.getElementById('main').style.direction == "rtl") {
            here.changeLocale('en');
            $("main").scrollTo({
              top: 0,
              left: document.querySelector("#en-home").getBoundingClientRect().left,
              behavior: "smooth",
            });
          }
          if (oldScrollX > document.getElementById('main').scrollLeft
            && document.querySelector("#en-home").getBoundingClientRect().left > 0
            && document.getElementById('main').style.direction == "ltr") {
            here.changeLocale('ar');
          }
          oldScrollX = document.getElementById('main').scrollLeft;
        }
      }

    },
    
    setupScroll() {
      var here = this;

      
      document.getElementById('main').addEventListener('wheel', function (event) {
        if(!here.$store.getters.getMenu)
        here.smoothScroll(document.getElementById('main'), event.deltaY, 300);
    
      })
    },
  },
  computed: {

    mobile() {
      return this.screen <= 1199.98;
    },
    locale() {
      return this.lang;
    }
  },
};
</script>
<style scoped>
main {
  direction: ltr;
  min-width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  width: auto;
  transition: none;
}



@media (min-width: 1200px) {

  main .section {
    min-width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    flex: none;
  }
}


@media (max-width: 1199.98px) {
  .position-mobile-relative {
    position: relative;
  }

  .arabic .ar-mobie-none {
    display: none;
  }

  .english .en-mobie-none {
    display: none;
  }

  main {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    width: auto;
  }

  main>.section {
    width: 100vw;
    overflow-x: hidden;
    flex: none;
    /* margin-top: 20%; */
  }

  #home {
    margin-top: 0% !important;
  }
}
</style>
